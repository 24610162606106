<template>
  <div class="h-full">
    <NuxtLoadingIndicator />
    <div>
      <div class="h-screen min-h-full ">
        <slot />
      </div>

      <footer class="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <LayoutFooterPoweredByWetix />
      </footer>
    </div>
    <UNotifications />
  </div>
</template>

<script setup lang="ts">

useHead({
  title: 'WeTix',
  meta: [
    // { name: 'description', content: 'My amazing site.' }
  ],
  bodyAttrs: {
    class: 'h-full'
  },
  htmlAttrs: {
    class: 'h-full'
  }
})

</script>
